function resizeClient() {
  var iframes = document.querySelectorAll("iframe.ap-embed")

  function onMessage(msg) {
    const msgType = msg.data.type
    switch (msgType) {
      case "embed-size":
        var iframe = null
        document.querySelectorAll("iframe.ap-embed").forEach(function (item) {
          if (msg.source === item.contentWindow) {
            iframe = item
          }
        })

        if (!iframe) {
          return
        }

        iframe.setAttribute("height", msg.data.height)
        iframe.style.height = msg.data.height + "px"
        return
      case "domain-request":
        msg.source.postMessage(
          {
            type: "domain-request",
          },
          msg.origin,
        )
        return
      default:
        return
    }
  }

  window.addEventListener("message", onMessage)

  iframes.forEach(function (iframe) {
    var data = { type: "embed-size-query" }
    iframe.contentWindow.postMessage(data, "*")
  })

  // Return a func to detach listeners for use with useEffect
  return function () {
    window.removeEventListener("message", onMessage)
  }
}

export default resizeClient
